@import "../../assets/bootstrap/color.scss";
@import "../../assets/bootstrap/font.scss";

.wrapper-notification-text {
    width: 520px;
    // height: 132px;
    // max-height: 90vh;
    z-index: 1097;
    position: fixed;
    background-color: $white;
    right: 0;
    top: 79px;
    box-shadow: 0px 6px 12px 0px rgba(15, 15, 15, 0.35);
    border-top: 4px solid $warning-msg-border-color;
    transition-property: max-height, margin;
    transition-duration: 0.5s, 0.5s;
    transition-timing-function: ease, ease;
    transition-delay: 0s, 0s;
    padding-bottom: 2.05rem;

    &--error {
        border-top: 4px solid $error-msg-border-color;
    }

    &--warning {
        border-top: 4px solid $warning-msg-border-color;
    }

    .wrapper-notification-data {
        display: flex;
        flex-direction: row;

        .notification-icon {
            width: 28px;
            height: 28px;
            margin-left: 16px;
            margin-top: 20px;
            font-size: $primary-font-size-lg;
            font-weight: bold;
            border-radius: 50%;
            text-align: center;
            background-color: $alert-icon-color;
            color: $white;
        }

        .failure-icon {
            margin-left: 1.6rem;
            width: 2.8rem;
            height: 2.8rem;
            margin-top: 2rem;
        }
        .notification-modal-text {
            height: 92px;
            width: 380px;
            margin-left: 16px;
            padding-top: 18.5px;
            font-size: $primary-font-size-lg;
        }
    }

    .confirmation-btn {
        // margin: 0px 28px;
        // marign
        margin-left: 60px;
        margin-right: 20px;
        display: flex;
        flex-direction: row;
        // justify-content: space-between;

        .ok-button {
            margin-left: auto;
        }

        .details {
            color: $primary-theme-color;
            text-decoration: underline;
            &:hover {
                cursor: pointer;
            }
        }
    }

    .list-details {
        margin-left: 60px;
    }
}

.wrapper-notification {
    position: fixed;
    width: 583px;
    height: 48px;
    // z-index: 9999;
    top: 7.9rem;
    right: 0;
}
