.modal-dialog {
    max-width: 70vw !important;
}

.modal-dialog.functionConfigCustom.create-model-modal {
    max-width: 96vw !important;
    overflow: hidden;
    height: 95vh;
}

.modal-header {
    .close {
        opacity: 1 !important;
        &:focus {
            outline: none !important;
        }
    }
}
