.customToolTip {
    position: relative;
    display: inline-block;

    p {
        font-family: inherit !important;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-bottom: 0;
        min-width: 200px;
        max-width: 250px;
    }
}

.customToolTip .tooltiptextCustom {
    visibility: hidden;
    display: none;
    // width: 100%;

    background-color: #fff;
    color: #000;
    text-align: left;
    border-radius: 6px;
    position: absolute;
    z-index: 9999999;
    left: 0%;
    top: 30px;
    border: 2px solid #d1d1d1;
    padding: 10px;
}

.customToolTip .tooltiptextCustom::after {
    content: " ";
    position: absolute;
    bottom: 100%;
    /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
}

.tooltip-hide {
    display: none;
    visibility: hidden;
}
.customToolTip:hover .tooltiptextCustom {
    visibility: visible;
    display: block;
    overflow-wrap: anywhere;
    min-width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}
