@import "./assets//bootstrap/color.scss";
@font-face {
    font-family: "abb-font-rg";
    src: url("./assets/Fonts/abb_font_all/ABBvoice_TWHK_V1.002/ABBvoice_TWHK_V1.002/Webfonts/WOFF/ABBvoice_WTWHK_Rg.woff");
}

@font-face {
    font-family: "abb-font-bd";
    src: url("./assets/Fonts/abb_font_all/ABBvoice_TWHK_V1.002/ABBvoice_TWHK_V1.002/Webfonts/WOFF/ABBvoice_WTWHK_Bd.woff");
}

@font-face {
    font-family: "abb-font-md";
    src: url("./assets/Fonts/abb_font_all/ABBvoice_TWHK_V1.002/ABBvoice_TWHK_V1.002/Webfonts/WOFF/ABBvoice_WTWHK_Md.woff");
}

html {
    font-size: 62.5%; /* Now 10px = 1rem! */
}

body {
    margin: 0;
    font-size: 1.4rem;
    min-height: 100vh;
    font-family: "abb-font-rg", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", "sans-serif";
    color: $primary-theme-text;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // background-color: $grey-white !important;
    background-color:  #fff !important;
    #root {
        min-height: inherit;
        overflow: auto;
    }
}
